import React from 'react';
import { FaHome } from 'react-icons/fa';
import { MdArrowForwardIos } from 'react-icons/md';
import styled from 'styled-components';

interface WebPathProps {
  subtitle: string;
  subtitle2?: string;
}

const WebPathContainer = styled.div`
  padding: 30px 50px;
  display: flex;
  width: 100%;
  gap: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // 드롭 쉐도우 추가
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const WebPath: React.FC<WebPathProps> = ({ subtitle, subtitle2 }) => {
  return (
    <WebPathContainer>
      <FaHome />
      <span>Home</span>
      <MdArrowForwardIos />
      <span>{subtitle}</span>
      {/* Conditionally render the arrow and subtitle2 only if subtitle2 is provided */}
      {subtitle2 && (
        <>
          <MdArrowForwardIos />
          <span>{subtitle2}</span>
        </>
      )}
    </WebPathContainer>
  );
};

export default WebPath;
