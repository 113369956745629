import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* 폰트 설정 */
  body {
    font-family: 'Noto Sans KR', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  button {
    font-weight: 700;
    font-family: 'Noto Sans KR', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* 스크롤바 커스터마이징 */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px;
    border: 3px solid #f1f1f1; /* Adds padding around scrollbar thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* 파이어폭스용 스크롤바 스타일 */
  body {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
  }
`;

export default GlobalStyle;
