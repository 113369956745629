import React from 'react';
import styled from 'styled-components';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;

  @media (max-width: 768px) {
    font-size: 14px; /* 작은 화면에서는 글씨 크기 축소 */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* 더 작은 화면에서는 글씨 크기 더 축소 */
  }
`;

interface RadioProps {
  label: string;
  value: string;
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioBox: React.FC<RadioProps> = ({
  label,
  value,
  name,
  checked,
  onChange,
}) => {
  const inputId = `radio-${value}`;

  return (
    <LabelContainer>
      <input
        id={inputId}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={inputId}>{label}</label>
    </LabelContainer>
  );
};

export default RadioBox;
