import styled from 'styled-components';
import SubBanner from '../../components/SubBanner';
import WebPath from '../../components/WebPath';
import SubHeaderContainer from '../../components/SubHeaderContainer';
import gicon from '../../assets/images/overviewPage/gicon.svg';
import gwangju from '../../assets/images/overviewPage/gwangju.svg';
import kcca from '../../assets/images/overviewPage/kcca.svg';
import kmga from '../../assets/images/overviewPage/kmga.svg';
import Footer from '../../components/Footer';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  word-break: keep-all;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 380px 40px;
  word-break: keep-all;
  @media (max-width: 1440px) {
    padding: 100px 200px 0px;
  }
  @media (max-width: 1024px) {
    padding: 100px 100px 0px;
  }
  @media (max-width: 968px) {
    padding: 20px;
  }
`;

const EventInfo = styled.div`
  padding: 40px 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 1.8;
  font-size: 20px;
  word-break: keep-all;
  @media (max-width: 1024px) {
    padding: 20px 5px;
    gap: 10px;
  }
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  word-break: keep-all;
  h3 {
    width: 100px;
  }
  span {
    font-size: 20px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    h3 {
      font-size: 17px;
    }
    span {
      font-size: 15px;
    }
  }
`;
const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 1024px) {
    gap: 20px;
  }
`;

function OverviewPage() {
  return (
    <PageContainer>
      <SubBanner title="행사 개요" />
      <WebPath subtitle="행사 개요" />
      <Container>
        <SubHeaderContainer title="행사 개요" />
        {/* HTML Content for the event details */}
        <EventInfo>
          <RowContainer>
            <h3>• 행사명 :</h3> <span>글로벌 게임 개발자 컨퍼런스</span>
          </RowContainer>
          <RowContainer>
            <h3>• 일자 :</h3> <span>2024.10.11(금) ~ 10.12(토) (2일간)</span>
            <span>
              ※ 개막식 : '24.10.11(금) 11:00 ~, 전일빌딩 다목적강당 (예정)
            </span>
          </RowContainer>
          <RowContainer>
            <h3>• 장소 :</h3>
            <span>
              전일빌딩 (광주 동구 금남로 245), 광주영상복합문화관 (광주 동구
              제봉로 96)
            </span>
          </RowContainer>
          <RowContainer>
            <h3>• 참석대상 :</h3>
            <span>국내외 게임 산업 종사자 및 예비개발자, 일반시민 등</span>
          </RowContainer>
          <RowContainer>
            <h3>• 주요내용 :</h3>
            <span>컨퍼런스 기획·운영, 부대행사, 네트워킹 프로그램 운영 등</span>
          </RowContainer>
          <RowContainer>
            <h3>• 주최 :</h3>
            <ImgContainer>
              <img src={gwangju} alt="" height={30} />
            </ImgContainer>
          </RowContainer>
          <RowContainer>
            <h3>• 주관 :</h3>
            <ImgContainer>
              <img src={gicon} alt="" height={30} />
            </ImgContainer>
          </RowContainer>
          <RowContainer>
            <h3>• 후원 :</h3>
            <ImgContainer>
              <img src={kcca} alt="" height={30} />
              <img src={kmga} alt="" height={50} />
            </ImgContainer>
          </RowContainer>
        </EventInfo>
      </Container>
      <Footer />
    </PageContainer>
  );
}

export default OverviewPage;
