// 엔드포인트 URL을 생성하는 함수의 타입을 정의합니다.
export interface AuthEndpointsProps {
  post_addNewResister: () => string;
}

// 인증 관련 엔드포인트 정의
export const ReceptionEndpoints: AuthEndpointsProps = {
  post_addNewResister: () => `api/ggdc/visitor/addNewResister`,
};

export default ReceptionEndpoints;
