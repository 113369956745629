import React from 'react';
import styled from 'styled-components';
import LabelWithRequiredMark from './LabelWithRequiredMark'; // 새롭게 분리된 컴포넌트 임포트

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  background-color: #ddd;
  border-radius: 5px;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 8px;
  }
`;

interface InputFieldProps {
  label: string;
  name: string;
  value: string;
  placeholder: string;
  type?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  type = 'text',
  required = false,
}) => {
  return (
    <InputContainer>
      <LabelWithRequiredMark label={label} required={required} />
      {/* 분리된 컴포넌트 사용 */}
      <Input
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
      />
    </InputContainer>
  );
};

export default InputField;
