import React, { useState, useEffect } from 'react';
import ResponsiveHeader from '../../components/ResponsiveHeader';
import styled from 'styled-components';
import BannerBG from '../../assets/images/mainPage/BannerBG.png';
import BannerText1 from '../../assets/images/mainPage/BannerText1.png';
import BannerText2 from '../../assets/images/mainPage/BannerText2.png';

const HeaderBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('${BannerBG}');
  background-size: cover;
  background-position: center;
  z-index: -2;
`;

const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* 검정색 반투명 오버레이 */
  z-index: -1;
`;

const BannerContainer = styled.div`
  width: 100%;
  color: #fff;
  padding: 100px 380px 0px;
  height: 100vh;
  @media (max-width: 1440px) {
    padding: 100px 200px 0px;
  }
  @media (max-width: 1024px) {
    padding: 100px 100px 0px;
  }
  @media (max-width: 968px) {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 668px) {
    padding: 20px;
  }
`;
const BannerText1Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 100px;

  @media (max-width: 1024px) {
    padding-top: 0px;
    width: 100%;
  }
  @media (max-width: 968px) {
    img {
      width: 100%;
    }
  }
`;
const BannerText2Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 20px;
  align-items: flex-end; /* 추가하여 텍스트가 이미지와 수직으로 정렬되도록 */
  gap: 20px;
  p {
    font-size: 80px;
    font-weight: 700;
  }
  @media (max-width: 1024px) {
    width: 100%;
    p {
      font-size: 50px;
    }
  }
  @media (max-width: 968px) {
    img {
      width: 70%;
    }
  }
  @media (max-width: 568px) {
    p {
      font-size: 30px;
    }
  }
`;

// Date difference calculation function
const calculateDaysLeft = (targetDate: Date): number => {
  const currentDate = new Date();
  const timeDifference = targetDate.getTime() - currentDate.getTime();
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
};

const HeaderBanner = () => {
  const [daysLeft, setDaysLeft] = useState<number>(0);

  useEffect(() => {
    const targetDate = new Date('2024-10-11');
    const days = calculateDaysLeft(targetDate);
    setDaysLeft(days);
  }, []);

  return (
    <HeaderBannerContainer>
      <BackgroundImage />
      <DarkOverlay />
      <ResponsiveHeader />
      <BannerContainer>
        <BannerText1Container>
          <img src={BannerText1} alt="GGDC 글로벌 게임 개발자 컨퍼런그" />
        </BannerText1Container>
        <BannerText2Container>
          {/* 남은 일수를 표시하는 텍스트 */}
          <p>D - {daysLeft}</p>
          <img src={BannerText2} alt="224.10.11~ 2024010.12" />
        </BannerText2Container>
      </BannerContainer>
    </HeaderBannerContainer>
  );
};

export default HeaderBanner;
