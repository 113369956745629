import React from 'react';
import SubBanner from '../../components/SubBanner';
import WebPath from '../../components/WebPath';

function ReceptionHeader() {
  return (
    <>
      <SubBanner title="사전접수" />
      <WebPath subtitle="사전접수" />
    </>
  );
}

export default ReceptionHeader;
