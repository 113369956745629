import axios from 'axios';

// Axios 인스턴스 생성
// const apiClient = axios.create({
//   //baseURL: 'http://172.27.1.2:11201/', // 기본 주소
//   baseURL: 'https://ggdc.or.kr/', // 기본 주소
// });

const isIpAddress = (hostname: string): boolean => {
  // 정규 표현식으로 IP 주소인지 확인
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipRegex.test(hostname);
};

const isLocalhost = (hostname: string): boolean => {
  // localhost 또는 127.0.0.1을 체크
  return hostname === 'localhost' || hostname === '127.0.0.1';
};

// 기본 주소 설정 (도메인과 IP 주소 또는 localhost 구분)
const baseURL = (() => {
  const hostname = window.location.hostname;

  if (isLocalhost(hostname) || isIpAddress(hostname)) {
    return 'http://172.27.1.2:11201/';
  }

  // 도메인일 경우
  return `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/`;
})();

const apiClient = axios.create({
  baseURL,
});

let isRefreshing = false;
const refreshSubscribers: ((token: string) => void)[] = [];

const onRefreshed = (token: string) => {
  refreshSubscribers.map((callback) => callback(token));
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

// 상태 코드에 따른 메시지
const statusMessages: { [key: number]: string } = {
  200: '요청이 성공적으로 처리되었습니다.',
  201: '리소스가 성공적으로 생성되었습니다.',
  202: '요청이 접수되었으나 처리가 완료되지 않았습니다.',
  204: '요청이 성공적으로 처리되었으나 응답할 콘텐츠가 없습니다.',
  301: '리소스가 영구적으로 이동되었습니다.',
  302: '리소스가 일시적으로 이동되었습니다.',
  304: '리소스가 수정되지 않았습니다.',
  400: '잘못된 요청입니다.',
  401: '인증이 필요합니다.',
  403: '접근이 금지되었습니다.',
  404: '리소스를 찾을 수 없습니다.',
  405: '허용되지 않는 메서드입니다.',
  406: '허용되지 않는 형식입니다.',
  408: '요청 시간이 초과되었습니다.',
  409: '리소스가 충돌되었습니다.',
  410: '리소스가 더 이상 존재하지 않습니다.',
  412: '전제 조건이 실패했습니다.',
  413: '요청 본문이 너무 큽니다.',
  414: '요청 URI가 너무 깁니다.',
  415: '지원되지 않는 미디어 형식입니다.',
  429: '요청이 너무 많습니다.',
  500: '서버 오류가 발생했습니다.',
  501: '서버가 요청된 기능을 지원하지 않습니다.',
  502: '잘못된 게이트웨이입니다.',
  503: '서비스를 사용할 수 없습니다.',
  504: '게이트웨이 시간이 초과되었습니다.',
  505: 'HTTP 버전이 지원되지 않습니다.',
};

// 요청 인터셉터 추가
apiClient.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem('accessToken') ||
      sessionStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    const message = statusMessages[response.status] || '요청이 성공했습니다.';
    response.data.message = message; // 응답 데이터에 메시지를 추가

    // Content-Range 헤더 확인 및 로그 출력
    const contentRange = response.headers['content-range'];
    if (contentRange) {
      console.log('Content-Range:', contentRange);
    } else {
      console.log('Content-Range 헤더가 없습니다.222222');
    }

    return response;
  },
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (response && response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshToken =
            localStorage.getItem('refreshToken') ||
            sessionStorage.getItem('refreshToken');
          if (!refreshToken) {
            throw new Error('No refresh token available');
          }

          // refreshToken을 accessToken으로 변경하여 원래의 요청을 다시 보냄
          originalRequest.headers.Authorization = `Bearer ${refreshToken}`;

          const refreshResponse = await apiClient(originalRequest);

          const newAccessToken = refreshResponse.headers['new-access-token'];
          const newRefreshToken = refreshResponse.headers['new-refresh-token'];

          if (newAccessToken && newRefreshToken) {
            localStorage.setItem('accessToken', newAccessToken);
            localStorage.setItem('refreshToken', newRefreshToken);
            sessionStorage.setItem('accessToken', newAccessToken);
            sessionStorage.setItem('refreshToken', newRefreshToken);

            apiClient.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

            onRefreshed(newAccessToken);
          }

          isRefreshing = false;
        } catch (err) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          sessionStorage.removeItem('accessToken');
          sessionStorage.removeItem('refreshToken');
          window.location.href = '/login'; // 로그인 페이지로 리디렉션
          return Promise.reject(err);
        }
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((token: string) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(apiClient(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    // 500 오류 처리
    if (response && response.status === 500) {
      const resultMsg = response.data?.reason || '서버 오류가 발생했습니다.';
      return Promise.reject(new Error(resultMsg)); // resultMsg를 반환
    }

    if (response) {
      const message =
        statusMessages[response.status] ||
        `알 수 없는 오류가 발생했습니다. 상태 코드: ${response.status}`;
      return Promise.reject(new Error(message));
    }

    if (!response) {
      return Promise.reject(
        new Error('네트워크 오류가 발생했습니다. 인터넷 연결을 확인해주세요.')
      );
    }

    return Promise.reject(error);
  }
);

export default apiClient;
