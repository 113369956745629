import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;

  @media (max-width: 768px) {
    font-size: 14px; /* 작은 화면에서는 글씨 크기 축소 */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* 더 작은 화면에서는 글씨 크기 더 축소 */
  }
`;

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  name,
}) => {
  return (
    <Label>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        name={name}
      />
      {label}
    </Label>
  );
};

export default Checkbox;
