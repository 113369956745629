import React from 'react';
import styled from 'styled-components';

// Styled components
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const HeaderTitle = styled.h1`
  text-align: center;
  padding: 40px;
  width: 100%;
  font-size: 40px;
  border-bottom: 1px solid #ccc;
  @media (max-width: 968px) {
    font-size: 30px;
    padding: 30px 20px;
  }
`;

// Define props type
interface SubHeaderContainerProps {
  title: string;
}

// Functional component with props
const SubHeaderContainer: React.FC<SubHeaderContainerProps> = ({ title }) => {
  return (
    <HeaderContainer>
      <HeaderTitle>{title}</HeaderTitle>
    </HeaderContainer>
  );
};

export default SubHeaderContainer;
