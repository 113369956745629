import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import MobileLogo from '../assets/images/MobileLogo.png';
import DesktopLogo from '../assets/images/DesktopLogo.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import {
  EventUrl,
  FAQUrl,
  MainUrl,
  AnnouncementUrl,
  OverviewUrl,
  ProgramUrl,
  ReceptionAgreementUrl,
  ReceptionUrl,
  SpeakerUrl,
} from '../url/ggdcUrl';

interface NavProps {
  datashow: string;
}

const HeaderContainer = styled.header<{ scrolled: string }>`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  color: #fff;
  background-color: ${(props) =>
    props.scrolled === 'true' ? '#000' : 'transparent'};
  transition: background-color 0.3s ease;
  z-index: 1000;
  @media (max-width: 968px) {
    padding: 15px 25px;
  }
  @media (max-width: 568px) {
    padding: 6px 16px;
  }
`;

const Logo = styled(Link)`
  @media (max-width: 968px) {
    padding: 10px;
    width: 100px;
  }
`;

const Nav = styled.nav<NavProps>`
  display: flex;
  align-items: center;
  width: 40%;
  @media (max-width: 1440px) {
    width: 70%;
  }
  @media (max-width: 968px) {
    width: calc(100% - 100px);
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 100px;
    bottom: 0;
    padding: 100px 20px;
    display: ${(props) => (props.datashow === 'true' ? 'flex' : 'none')};
    background-color: #262626;
    z-index: 1050;
    justify-content: flex-start;
  }
`;

const NavLink = styled(Link)<{ $active: boolean }>`
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 999px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  transition:
    color 0.3s,
    font-weight 0.3s;

  &:hover {
    background-color: #fff;
    color: ${(props) => props.theme.colors.mainColor};
    font-weight: 700;
  }

  ${({ $active }) =>
    $active &&
    css`
      border-radius: 999px;
      background-color: #fff;
      color: ${(props) => props.theme.colors.mainColor};
      font-weight: 700;
    `}

  @media (max-width: 968px) {
    border-radius: 0px;
    border-bottom: 1px solid #333;
    padding: 20px 0;
    margin: 0px;
  }
`;

const NavItemWithDropdown = styled.div<{ $isHovered: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover > div {
    display: block; /* 데스크탑에서 서브메뉴가 hover 시에만 보이도록 */
  }

  &:hover > div,
  &:hover > div:hover {
    display: block;
  }

  &:hover > div,
  &:hover > div a {
    width: 100%; /* NavLink와 동일한 크기로 설정 */
  }

  ${({ $isHovered }) =>
    $isHovered &&
    css`
      > div:first-child {
        border-radius: 999px;
        background-color: #fff;
        color: ${(props) => props.theme.colors.mainColor};
        font-weight: 700;
      }
    `}

  @media (max-width: 968px) {
    ${({ $isHovered }) =>
      $isHovered &&
      css`
        > div:first-child {
          border-radius: 0px;
          background-color: #363636;
          color: #fff;
          font-weight: 400;
        }
      `}
  }
`;

const MainMenu = styled.div<{ $active: boolean }>`
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  transition:
    color 0.3s,
    font-weight 0.3s;
  margin: 10px 0;

  ${({ $active }) =>
    $active &&
    css`
      background-color: #fff;
      color: ${(props) => props.theme.colors.mainColor};
      font-weight: 700;
    `}
`;

const SubMenu = styled.div<{ $isOpen: boolean }>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  text-align: center;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 100%; /* NavLink와 같은 너비로 설정 */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  padding: 10px;

  a {
    font-size: 17px;
    color: ${(props) => props.theme.colors.mainColor};
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: ${(props) => props.theme.colors.mainColor};
      color: #fff;
      font-weight: 700;
      border-radius: 999px;
    }
  }

  @media (max-width: 968px) {
    position: relative;
    width: 100%;
    top: auto;
    left: auto;
    box-shadow: none;
    border-radius: 0px;
    background-color: #474747;

    a {
      color: #fff;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageSwitcher = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  @media (max-width: 968px) {
    margin-top: auto;
  }
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  display: none;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 968px) {
    display: flex;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  @media (max-width: 968px) {
    display: flex;
  }
`;

const Overlay = styled.div<{ datashow: string }>`
  display: none;
  @media (max-width: 968px) {
    display: ${(props) => (props.datashow === 'true' ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }
`;

const ResponsiveHeader: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const handleNavLinkClick = (to: string) => {
    if (location.pathname === to) {
      window.scrollTo(0, 0);
    }
    setShowMenu(false);
  };

  const handleHoverEnter = () => {
    setIsHovered(true);
    setIsSubMenuOpen(true);
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
    setIsSubMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isActive = (path: string) => location.pathname === path;
  const isActiveBoard = (path: string) =>
    path === AnnouncementUrl || path === FAQUrl;

  const isActiveReception = (path: string) => {
    return path === ReceptionUrl || path === ReceptionAgreementUrl;
  };

  return (
    <>
      <HeaderContainer scrolled={scrolled.toString()}>
        <Logo to={MainUrl}>
          <img
            src={window.innerWidth <= 968 ? MobileLogo : DesktopLogo}
            alt="Logo"
            width={window.innerWidth <= 968 ? 100 : 130}
          />
        </Logo>
        <Overlay datashow={showMenu.toString()} onClick={toggleMenu} />
        <Nav datashow={showMenu.toString()}>
          <CloseButton onClick={toggleMenu}>
            <FaTimes />
          </CloseButton>
          <NavLink
            to={OverviewUrl}
            $active={isActive(OverviewUrl)}
            onClick={() => handleNavLinkClick(OverviewUrl)}
          >
            행사 개요
          </NavLink>
          <NavLink
            to={SpeakerUrl}
            $active={isActive(SpeakerUrl)}
            onClick={() => handleNavLinkClick(SpeakerUrl)}
          >
            연사 소개
          </NavLink>
          <NavLink
            to={ProgramUrl}
            $active={isActive(ProgramUrl)}
            onClick={() => handleNavLinkClick(ProgramUrl)}
          >
            프로그램
          </NavLink>
          <NavLink
            to={EventUrl}
            $active={isActive(EventUrl)}
            onClick={() => handleNavLinkClick(EventUrl)}
          >
            이벤트
          </NavLink>
          {/* 게시판 클릭 시 페이지 이동 없이 서브메뉴 열기 */}
          <NavItemWithDropdown
            $isHovered={isHovered || isActiveBoard(location.pathname)} // 서브메뉴 경로가 활성화된 경우도 hover 상태로 처리
            onMouseEnter={handleHoverEnter}
            onMouseLeave={handleHoverLeave}
          >
            <MainMenu
              onClick={toggleSubMenu}
              $active={isActiveBoard(location.pathname)}
            >
              게시판
            </MainMenu>
            <SubMenu $isOpen={isSubMenuOpen}>
              <Link to={AnnouncementUrl}>공지사항</Link>
              <Link to={FAQUrl}>FAQ</Link>
            </SubMenu>
          </NavItemWithDropdown>
        </Nav>
        <RightContainer>
          <LanguageSwitcher>
            <NavLink
              to={ReceptionAgreementUrl}
              $active={isActiveReception(location.pathname)} // 두 개의 경로에 대해 active 상태 설정
              onClick={() => handleNavLinkClick(ReceptionAgreementUrl)}
              style={{
                padding: '5px 20px',
                border: 'none',
                borderRadius: '999px',
                marginRight: 10,
              }}
            >
              사전접수
            </NavLink>
          </LanguageSwitcher>
          <MenuButton onClick={toggleMenu}>
            <FaBars color="#fff" style={{ fontSize: '24px' }} />
          </MenuButton>
        </RightContainer>
      </HeaderContainer>
    </>
  );
};

export default ResponsiveHeader;
