// 엔드포인트 URL을 생성하는 함수의 타입을 정의합니다.
export interface AuthEndpointsProps {
  post_signup: () => string;
  post_login: () => string;
}

// 인증 관련 엔드포인트 정의
export const AuthEndpoints: AuthEndpointsProps = {
  post_signup: () => `api/streaming/sign/sign-up`,
  post_login: () => `api/streaming/sign/login`,
};

export default AuthEndpoints;
