import React, { useState } from 'react';
import styled from 'styled-components';
import ReceptionHeader from '../../../components/receptionPage/ReceptionHeader';
import Checkbox from '../../../components/Checkbox';
import RadioBox from '../../../components/RadioBox';
import InputField from '../../../components/InputField';
import ReceptionTitle from '../../../components/ReceptionTitle';
import { useNavigate } from 'react-router-dom';
import LabelWithRequiredMark from '../../../components/LabelWithRequiredMark';
import {
  ReceptionData,
  receptionAdd,
} from '../../../api/service/reception/receptionService';
import { MainUrl } from '../../../url/ggdcUrl';
import SubHeaderContainer from '../../../components/SubHeaderContainer';
import Footer from '../../../components/Footer';

// 스타일 컴포넌트 정의
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 380px 40px;
  @media (max-width: 1440px) {
    padding: 100px 200px 0px;
  }
  @media (max-width: 1024px) {
    padding: 100px 100px 0px;
  }
  @media (max-width: 968px) {
    padding: 20px;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 0px;
  @media (max-width: 968px) {
    gap: 20px;
  }
`;

const BoxContainer = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid #ccc;
  @media (max-width: 968px) {
    padding-bottom: 20px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
`;
const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 968px) {
    gap: 10px;
  }
`;

const InputBoxContainer = styled.div`
  display: flex;
  padding-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: 968px) {
    gap: 10px;
  }
`;

const RadioBoxContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const Select = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 400px;
  margin-top: 20px;
  @media (max-width: 968px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-bottom: 40px;
`;

const Button1 = styled.button`
  background-color: ${(props) => props.theme.colors.mainColor};
  color: ${(props) => props.theme.colors.whiteColor};
  border: none;
  border: 1px solid ${(props) => props.theme.colors.mainColor};
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.hoverColor};
  }
`;

const Button2 = styled.button`
  background-color: ${(props) => props.theme.colors.whiteColor};
  color: ${(props) => props.theme.colors.textColor};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #888;

  &:hover {
    border: 1px solid #bbbbbb;
    background-color: #bbbbbb;
  }
`;

function ReceptionPage() {
  const navigate = useNavigate(); // 페이지 이동을 위한 hook
  const [visitDates, setVisitDates] = useState({
    '10월 11일': true,
    '10월 12일': false,
    '양일간 참석': false,
  });

  // 추가된 상태: '기타' 항목에 대해 입력한 값을 저장
  const [customInflowPath, setCustomInflowPath] = useState('');
  const [customInterestField, setCustomInterestField] = useState('');

  const [formData, setFormData] = useState<ReceptionData>({
    visitDate: '10월 11일', // 첫 번째 체크박스 선택됨
    visitorName: '',
    visitorAffiliation: '',
    position: '',
    gender: 'MALE', // 첫 번째 라디오 버튼 선택됨
    visitorAge: 'TEENS', // 첫 번째 라디오 버튼 선택됨
    visitorEmail: '',
    visitorMobilePhone: '',
    visitorLandlinePhone: '',
    visitorRegion: 'SEOUL', // 첫 번째 라디오 버튼 선택됨
    affiliationCategory: '',
    participationPurposes: ['TREND_RESEARCH'], // 첫 번째 체크박스 선택됨
    interestField: 'PLANNING_DEVELOPMENT',
    inflowPath: 'ORGANIZATION_ASSOCIATION',
  });

  // 휴대전화 번호 포맷팅 함수
  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const cleaned = value.replace(/\D/g, ''); // 숫자가 아닌 문자는 모두 제거
    const match = cleaned.match(/^(\d{3})(\d{3,4})(\d{4})$/);

    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  };

  // input 필드 변경 처리 함수
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    let formattedValue = value;

    // 휴대전화 및 일반전화에 대해 포맷팅 처리
    if (name === 'visitorMobilePhone' || name === 'visitorLandlinePhone') {
      formattedValue = formatPhoneNumber(value);
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  // '기타'에 대한 입력 필드 값 변경 처리 함수
  const handleCustomInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (name === 'customInflowPath') {
      setCustomInflowPath(value);
    } else if (name === 'customInterestField') {
      setCustomInterestField(value);
    }
  };

  // checkbox 변경 처리 함수
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedPurposes = checked
      ? [...formData.participationPurposes, name]
      : formData.participationPurposes.filter((purpose) => purpose !== name);

    setFormData({
      ...formData,
      participationPurposes: updatedPurposes,
    });
  };

  // radio box 변경 처리 함수
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleMultipleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;

    let updatedDates = {
      ...visitDates,
      [name]: checked,
    };

    // '양일간 참석'이 선택되면 '10월 11일'과 '10월 12일'도 모두 선택됨
    if (name === '양일간 참석') {
      updatedDates = {
        '10월 11일': checked,
        '10월 12일': checked,
        '양일간 참석': checked,
      };
    } else {
      // 개별 날짜가 선택된 경우 처리
      updatedDates['양일간 참석'] =
        updatedDates['10월 11일'] && updatedDates['10월 12일'];
    }

    setVisitDates(updatedDates);

    // 선택된 날짜들을 반영
    let selectedDate = '';
    if (updatedDates['양일간 참석']) {
      selectedDate = '양일간 참석';
    } else if (updatedDates['10월 11일'] && updatedDates['10월 12일']) {
      selectedDate = '양일간 참석';
    } else if (updatedDates['10월 11일']) {
      selectedDate = '10월 11일';
    } else if (updatedDates['10월 12일']) {
      selectedDate = '10월 12일';
    }

    setFormData({
      ...formData,
      visitDate: selectedDate, // 선택된 날짜 반영
    });
  };

  // 유효성 검사 함수
  const validateForm = () => {
    // newErrors는 객체로 초기화해야 함
    const newErrors: { [key: string]: string } = {};
    let errorMessage = '';

    // 필수 필드 체크 및 에러 메시지 누적
    if (!formData.visitorName) {
      newErrors.visitorName = '성명을 입력하세요.';
      errorMessage += '성명을 입력하세요.\n';
    }
    if (!formData.visitorEmail) {
      newErrors.visitorEmail = '이메일을 입력하세요.';
      errorMessage += '이메일을 입력하세요.\n';
    }
    if (!formData.visitorMobilePhone) {
      newErrors.visitorMobilePhone = '휴대전화를 입력하세요.';
      errorMessage += '휴대전화를 입력하세요.\n';
    }
    if (!formData.affiliationCategory) {
      newErrors.affiliationCategory = '소속분류를 선택해주세요.';
      errorMessage += '소속분류를 선택해주세요.\n';
    }

    // 에러가 있을 경우 경고창 띄우기
    if (errorMessage) {
      window.alert(errorMessage);
    }

    // 에러가 없으면 true, 있으면 false 반환
    return Object.keys(newErrors).length === 0;
  };

  // 폼 제출 처리 함수
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // 폼 유효성 검사
    const isValid = validateForm();

    if (!isValid) {
      return; // 필수 항목이 비어 있으면 제출 중지
    }

    try {
      // receptionAdd 함수 호출하여 데이터 전송
      console.log('전송 데이터', formData);
      const response = await receptionAdd(formData); // API 호출 후 응답을 받아옴
      if (response.status === 201) {
        alert('사전접수에 성공 하셨습니다.'); // 성공 상태
        navigate(MainUrl);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(`${error}`);
    }
  };

  return (
    <PageContainer>
      <ReceptionHeader />
      <Container>
        <SubHeaderContainer title="사전접수 안내" />
        <Form onSubmit={handleSubmit}>
          {/* 관람 예정일자 */}
          <BoxContainer>
            <ReceptionTitle title="관람 예정일자" />
            <InputBoxContainer>
              <Checkbox
                label="10월 11일(금)"
                name="10월 11일"
                checked={visitDates['10월 11일']}
                onChange={handleMultipleCheckboxChange}
              />
              <Checkbox
                label="10월 12일(토)"
                name="10월 12일"
                checked={visitDates['10월 12일']}
                onChange={handleMultipleCheckboxChange}
              />
              <Checkbox
                label="양일간 참석"
                name="양일간 참석"
                checked={visitDates['양일간 참석']}
                onChange={handleMultipleCheckboxChange}
              />
            </InputBoxContainer>
          </BoxContainer>
          {/* 개인정보 */}
          <BoxContainer>
            <ReceptionTitle title="개인정보" />
            <InputField
              label="성명"
              name="visitorName"
              value={formData.visitorName}
              placeholder="성명"
              onChange={handleInputChange}
              required={true}
            />
            <InputField
              label="소속"
              name="visitorAffiliation"
              value={formData.visitorAffiliation}
              placeholder="소속"
              onChange={handleInputChange}
            />
            <InputField
              label="직함"
              name="position"
              value={formData.position}
              placeholder="직함"
              onChange={handleInputChange}
            />
            {/* 성별 */}
            <InputContainer>
              <LabelWithRequiredMark label="성별" required={true} />
              <RadioBoxContainer>
                <RadioBox
                  label="남"
                  name="gender"
                  value="MALE"
                  checked={formData.gender === 'MALE'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="여"
                  name="gender"
                  value="FEMALE"
                  checked={formData.gender === 'FEMALE'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="기타"
                  name="gender"
                  value="OTHER"
                  checked={formData.gender === 'OTHER'}
                  onChange={handleRadioChange}
                />
              </RadioBoxContainer>
            </InputContainer>
            {/* 나이 */}
            <InputContainer>
              <LabelWithRequiredMark label="나이" required={true} />
              <RadioBoxContainer>
                <RadioBox
                  label="10대"
                  name="visitorAge"
                  value="TEENS"
                  checked={formData.visitorAge === 'TEENS'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="20대"
                  name="visitorAge"
                  value="TWENTIES"
                  checked={formData.visitorAge === 'TWENTIES'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="30대"
                  name="visitorAge"
                  value="THIRTIES"
                  checked={formData.visitorAge === 'THIRTIES'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="40대"
                  name="visitorAge"
                  value="FORTIES"
                  checked={formData.visitorAge === 'FORTIES'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="50대"
                  name="visitorAge"
                  value="FIFTIES"
                  checked={formData.visitorAge === 'FIFTIES'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="60대 이상"
                  name="visitorAge"
                  value="SIXTIES_PLUS"
                  checked={formData.visitorAge === 'SIXTIES_PLUS'}
                  onChange={handleRadioChange}
                />
              </RadioBoxContainer>
            </InputContainer>
            {/* 연락처 */}
            <InputField
              label="이메일"
              name="visitorEmail"
              value={formData.visitorEmail}
              placeholder="이메일"
              onChange={handleInputChange}
              required={true}
              type="email"
            />
            <PhoneContainer>
              <InputField
                label="휴대전화"
                name="visitorMobilePhone"
                value={formData.visitorMobilePhone}
                placeholder="휴대전화"
                onChange={handleInputChange}
                required={true}
                type="tel"
              />
              <InputField
                label="일반전화"
                name="visitorLandlinePhone"
                value={formData.visitorLandlinePhone}
                placeholder="일반전화"
                onChange={handleInputChange}
                type="tel"
              />
            </PhoneContainer>
            {/* 지역 */}
            <InputContainer>
              <LabelWithRequiredMark label="지역" required={true} />
              <RadioBoxContainer>
                <RadioBox
                  label="서울"
                  name="visitorRegion"
                  value="SEOUL"
                  checked={formData.visitorRegion === 'SEOUL'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="인천/경기"
                  name="visitorRegion"
                  value="INCHEON_GYEONGGI"
                  checked={formData.visitorRegion === 'INCHEON_GYEONGGI'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="강원"
                  name="visitorRegion"
                  value="GANGWON"
                  checked={formData.visitorRegion === 'GANGWON'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="대전/충남"
                  name="visitorRegion"
                  value="DAEJEON_CHUNNAM"
                  checked={formData.visitorRegion === 'DAEJEON_CHUNNAM'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="광주/전라"
                  name="visitorRegion"
                  value="GWANGJU_JEONNAM"
                  checked={formData.visitorRegion === 'GWANGJU_JEONNAM'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="대구/경북"
                  name="visitorRegion"
                  value="DAEGU_GYEONGBUK"
                  checked={formData.visitorRegion === 'DAEGU_GYEONGBUK'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="부산/울산/경남"
                  name="visitorRegion"
                  value="BUSAN_ULSAN_GYEONGNAM"
                  checked={formData.visitorRegion === 'BUSAN_ULSAN_GYEONGNAM'}
                  onChange={handleRadioChange}
                />
                <RadioBox
                  label="제주"
                  name="visitorRegion"
                  value="JEJU"
                  checked={formData.visitorRegion === 'JEJU'}
                  onChange={handleRadioChange}
                />
              </RadioBoxContainer>
            </InputContainer>
          </BoxContainer>
          {/* 소속 분류 */}
          <BoxContainer>
            <ReceptionTitle title="소속분류" />
            <Select
              name="affiliationCategory"
              value={formData.affiliationCategory}
              onChange={handleInputChange}
            >
              <option value="">선택하세요</option>
              <option value="게임기업">게임기업</option>
              <option value="유관기관/협회">유관기관/협회</option>
              <option value="일반인">일반인</option>
              <option value="미디어">미디어</option>
              <option value="학생">학생</option>
              <option value="기타">기타</option>
            </Select>
          </BoxContainer>
          {/* 참여 목적 */}
          <BoxContainer>
            <ReceptionTitle title="참여 목적(중복 선택 가능)" />
            <InputBoxContainer>
              <Checkbox
                label="게임 산업 관련 기술/트렌드 동향 파악"
                name="TREND_RESEARCH"
                checked={formData.participationPurposes.includes(
                  'TREND_RESEARCH'
                )}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label="컨퍼런스/연구"
                name="CONFERENCE_RESEARCH"
                checked={formData.participationPurposes.includes(
                  'CONFERENCE_RESEARCH'
                )}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label="전시/관람"
                name="EXHIBITION_VIEWING"
                checked={formData.participationPurposes.includes(
                  'EXHIBITION_VIEWING'
                )}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label="부대프로그램 행사 참여"
                name="PROGRAM_EVENT"
                checked={formData.participationPurposes.includes(
                  'PROGRAM_EVENT'
                )}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label="네트워킹데이"
                name="NETWORKING_DAY"
                checked={formData.participationPurposes.includes(
                  'NETWORKING_DAY'
                )}
                onChange={handleCheckboxChange}
              />
            </InputBoxContainer>
          </BoxContainer>
          {/* 관심분야 */}
          <BoxContainer>
            <ReceptionTitle title="관심분야" />
            <InputBoxContainer>
              <RadioBox
                label="기획/개발"
                name="interestField"
                value="PLANNING_DEVELOPMENT"
                checked={formData.interestField === 'PLANNING_DEVELOPMENT'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="디자인"
                name="interestField"
                value="DESIGN"
                checked={formData.interestField === 'DESIGN'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="경영"
                name="interestField"
                value="MANAGEMENT"
                checked={formData.interestField === 'MANAGEMENT'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="마케팅"
                name="interestField"
                value="MARKETING"
                checked={formData.interestField === 'MARKETING'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="법률"
                name="interestField"
                value="LEGAL"
                checked={formData.interestField === 'LEGAL'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="취/창업"
                name="interestField"
                value="JOB_CREATION"
                checked={formData.interestField === 'JOB_CREATION'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="학술, 정책관련"
                name="interestField"
                value="ACADEMIC_POLICY"
                checked={formData.interestField === 'ACADEMIC_POLICY'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="기타(직접입력)"
                name="interestField"
                value="OTHER"
                checked={formData.interestField === 'OTHER'}
                onChange={handleRadioChange}
              />
            </InputBoxContainer>
            {/* 기타 선택 시 입력 필드 표시 */}
            {formData.interestField === 'OTHER' && (
              <InputField
                label="기타"
                name="customInterestField"
                value={customInterestField}
                placeholder="관심 분야를 입력하세요"
                onChange={handleCustomInputChange}
              />
            )}
          </BoxContainer>
          {/* 참관 경로 */}
          <BoxContainer>
            <ReceptionTitle title="참관 경로" />
            <InputBoxContainer>
              <RadioBox
                label="유관기간/협회/단체"
                name="inflowPath"
                value="ORGANIZATION_ASSOCIATION"
                checked={formData.inflowPath === 'ORGANIZATION_ASSOCIATION'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="홈페이지/뉴스레터"
                name="inflowPath"
                value="WEBSITE_NEWSLETTER"
                checked={formData.inflowPath === 'WEBSITE_NEWSLETTER'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="홍보물"
                name="inflowPath"
                value="PROMOTIONAL_MATERIAL"
                checked={formData.inflowPath === 'PROMOTIONAL_MATERIAL'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="SNS"
                name="inflowPath"
                value="SNS"
                checked={formData.inflowPath === 'SNS'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="유관기관 사이트 배너광고"
                name="inflowPath"
                value="ORGANIZATION_BANNER_AD"
                checked={formData.inflowPath === 'ORGANIZATION_BANNER_AD'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="문자서비스"
                name="inflowPath"
                value="SMS_SERVICE"
                checked={formData.inflowPath === 'SMS_SERVICE'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="방송"
                name="inflowPath"
                value="BROADCASTING"
                checked={formData.inflowPath === 'BROADCASTING'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="신문/잡지광고"
                name="inflowPath"
                value="NEWSPAPER_MAGAZINE_AD"
                checked={formData.inflowPath === 'NEWSPAPER_MAGAZINE_AD'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="지인/거래처 추천"
                name="inflowPath"
                value="REFERRAL"
                checked={formData.inflowPath === 'REFERRAL'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="이메일 안내"
                name="inflowPath"
                value="EMAIL_GUIDE"
                checked={formData.inflowPath === 'EMAIL_GUIDE'}
                onChange={handleRadioChange}
              />
              <RadioBox
                label="기타(직접입력)"
                name="inflowPath"
                value="OTHER"
                checked={formData.inflowPath === 'OTHER'}
                onChange={handleRadioChange}
              />
            </InputBoxContainer>
            {/* 기타 선택 시 입력 필드 표시 */}
            {formData.inflowPath === 'OTHER' && (
              <InputField
                label="기타"
                name="customInflowPath"
                value={customInflowPath}
                placeholder="참관 경로를 입력하세요"
                onChange={handleCustomInputChange}
              />
            )}
          </BoxContainer>
          <ButtonContainer>
            <Button1 type="submit">접수하기</Button1>
            <Button2 onClick={() => navigate(-1)}>취소</Button2>{' '}
            {/* 취소 버튼 */}
          </ButtonContainer>
        </Form>
      </Container>
      <Footer />
    </PageContainer>
  );
}

export default ReceptionPage;
