import React, { useState } from 'react';
import styled from 'styled-components';
import SubBanner from '../../../components/SubBanner';
import WebPath from '../../../components/WebPath';
import SubHeaderContainer from '../../../components/SubHeaderContainer';
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';
import Footer from '../../../components/Footer';

// Styled components for layout
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 380px 40px;
  @media (max-width: 1440px) {
    padding: 100px 200px 0px;
  }
  @media (max-width: 1024px) {
    padding: 100px 100px 0px;
  }
  @media (max-width: 968px) {
    padding: 20px;
  }
`;

const FaqItemContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
`;

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  font-weight: bold;
  cursor: pointer;
`;

const Answer = styled.div<{ $show: boolean }>`
  background-color: #dfdfdf;
  padding: 15px 10px;
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 5px;
`;

// Define props types
interface FaqItemProps {
  question: string;
  answer: JSX.Element; // answer is JSX.Element now
}

const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <FaqItemContainer>
      <Question onClick={toggleAnswer}>
        {question} {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
      </Question>
      <Answer $show={isOpen}>{answer}</Answer>
    </FaqItemContainer>
  );
};

// Main FaqPage component
const FaqPage: React.FC = () => {
  const faqItems: FaqItemProps[] = [
    {
      question: '컨퍼런스는 언제 어디서 개최되나요?',
      answer: (
        <>
          <p>
            - 10월 11일부터 12일까지 전일빌딩245와 광주영상복합문화관에서
            개최됩니다.
          </p>
          <p>: 전일빌딩245 (광주 동구 금남로 245)</p>
          <p>: 광주영상복합문화관 (광주 동구 제봉로 96)</p>
        </>
      ),
    },
    {
      question: '컨퍼런스 입장료는 어떻게 되나요?',
      answer: (
        <p>
          입장료 무료입니다. 단, 개인정보 수집 및 이용에 동의하신 분에 한하여
          신청 가능합니다.
        </p>
      ),
    },
    {
      question: '컨퍼런스 참가 신청은 어떻게 하나요?',
      answer: (
        <>
          <p>
            {' '}
            2024년 10월 9일까지 홈페이지(https://ggdc.or.kr/)에서 신청
            가능합니다.
          </p>
          <p>
            개인정보 수집 및 이용에 동의하신 분에 한하여 신청 가능하며, 입장료는
            무료입니다.
          </p>
          <p>
            사전 등록 하신 분께는 2024년 10월 10일에 입장바코드가 일괄적으로
            발송 예정입니다.
          </p>
          <p>
            온라인 사전등록자 및 현장 등록은 전일빌딩245 8층 다목적홀 무인 등록
            데스크에서 출입증이 발급됩니다.
          </p>
        </>
      ),
    },
    {
      question: '행사 세부 일정은 어떻게 되나요?',
      answer: <>리플렛 다운로드 창 (링크 추후 추가)</>,
    },
    {
      question: '행사장에 주차할 수 있나요?',
      answer: <>별도 주차 공간이 없으므로 대중교통 이용을 추천드립니다.</>,
    },
    {
      question: '장소 내에 허용되지 않는 물건이 있나요?',
      answer: (
        <>
          <p>
            안전상의 이유로 행사 장소 내에는 무기류, 소음이 심한 악기류, 액체
            물질을 포함한 가연성 물질 그리고 약물과 같은 불법 물질이 금지됩니다.
          </p>
          <p>
            모든 참가자분들께서는 이러한 규정을 준수하고 행사 참석자들의 안전과
            보안을 존중해 주시기 바랍니다.
          </p>
        </>
      ),
    },
  ];

  return (
    <PageContainer>
      <SubBanner title="FAQ" />
      <WebPath subtitle="게시판" subtitle2="FAQ" />
      <Container>
        <SubHeaderContainer title="FAQ" />
        {faqItems.map((item, index) => (
          <FaqItem key={index} question={item.question} answer={item.answer} />
        ))}
      </Container>
      <Footer />
    </PageContainer>
  );
};

export default FaqPage;
