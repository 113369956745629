import React from 'react';

function Agreement() {
  return (
    <div style={{ lineHeight: '20px' }}>
      <p>
        주식회사 원더플랜 컴퍼니(이하 ‘회사’)는 정보주체의 자유와 권리 보호를
        위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여,적법하게
        개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」
        제30조에 따라 정보주체에게 개인정보의 처리와 보호에 관한 절차 및 기준을
        안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록하기
        위하여 다음과 같이 개인정보 처리방침을 수립・공개합니다.
      </p>
      <br />
      <h3>제1조 개인정보의 처리 목적</h3>
      <br />
      <p>
        회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
        변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는
        등 필요한 조치를 이행할 예정입니다.
      </p>
      <ul>
        <li>
          • 행사 입장을 위한 사전 등록 정보 처리 행사 입장 시 신원 확인, 참가
          신청 및 등록 확인, 행사 입장을 위한 본인 확인을 목적으로 개인정보를
          처리합니다.
        </li>
        <li>
          • 민원 사무 처리 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한
          연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
        </li>
        <li>
          • 재화 또는 서비스 제공 자사 개최 전시회 및 세미나 서비스 제공, 콘텐츠
          제공을 목적으로 개인정보를 처리합니다.
        </li>
        <li>
          • 마케팅 및 광고에의 활용 새로운 서비스 및 전시회나 이벤트에 대한 정보
          안내(제공), 고지사항 전달을 그 목적으로 합니다.
        </li>
      </ul>
      <br />
      <h3>제2조 개인정보의 처리 항목</h3>
      <br />
      <p>
        회사는 서비스 제공을 위해 필요 최소한의 범위에서 개인정보를
        수집・이용합니다. 다음의 개인정보 항목을 「개인정보 보호법」 제15조
        제1항 제1호 및 제22조 제1항 제7호에 따라 정보주체의 동의를 받아 처리하고
        있습니다.
      </p>
      <ul>
        <li>
          • 행사 사전 등록
          <ul>
            <li>수집 목적 수집 항목 보유 및 이용기간</li>
            <li>
              행사 사전 등록 및 행사 입장 시 인증 성명, 소속, 직함, 성별, 나이,
              e-mail, 휴대전화번호, 일반전화번호, 지역, 소속 분류(카테고리),
              관람 목적, 관심 분야, 참관 경로, 관람 예정 일자 2년
            </li>
            <li>
              행사 사전 등록 및 행사 입장 시 인증 성명, 소속, 직함, 성별, 나이,
              e-mail, 휴대전화번호, 일반전화번호, 지역, 소속 분류(카테고리),
              관람 목적, 관심 분야, 참관 경로, 관람 예정 일자 2년
            </li>
            <li>
              행사 소식 안내 및 등록 관련 안내를 위한 e-mail 발송 성명, e-mail
              수신거부 신청시 까지
            </li>
          </ul>
        </li>
        <li>
          • 행사 현장
          <ul>
            <li>
              행사 현장에서는 사진 및 영상이 촬영되며, 이는 홍보/마케팅 자료로
              활용될 수 있습니다. 이용자는 회사측에 사전/사후 활용철회를 요구할
              수 있습니다.
            </li>
            <li>- 수집항목: 성명, 휴대폰번호, 바코드 입장 정보</li>
            <li>- 수집/이용 목적: 수집/이용 목적</li>
            <li>
              - 관람객들의 편의와 참가업체 상호간의 유용한 정보 교류 서비스
              제공을 위한 전시회 출입증 발급 및 전자명함시스템 제공에 이용되며,
              본 행사에서 수집한 개인정보는 차기 전시회 초대권 발송 및 안내를
              위한 용도 외의 다른 목적으로 사용되지 않습니다.
            </li>
          </ul>
        </li>
      </ul>
      <br />
      <h3>제3조 개인정보의 처리 및 보유 기간</h3>
      <br />
      <p>
        회사는 법령에 따른 개인정보 보유・이용기간 또는 정보주체로부터
        개인정보를 수집 시에 동의 받은 개인정보 보유・이용기간 내에서 개인정보를
        처리・보유합니다. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
      </p>
      <ul>
        <li>• 전시회 사전/현장 등록 기록: 2년</li>
        <li>• 전시회 마케팅 광고 수신 거부 내역: 10년</li>
        <li>• 관람객의 불만 또는 분쟁처리에 관한 기록: 3년</li>
        <li>
          다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
          보유합니다v •관계 법령 위반에 따른 수사・조사 등이 진행 중인 경우에는
          해당 수사・조사 종료 시까지
        </li>
      </ul>
      <br />
      <h3>제4조 개인정보의 파기 절차 및 방법</h3>
      <br />
      <p>
        회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게
        되었을 때에는 지체없이 해당 개인정보를 파기합니다.
      </p>
      <p>
        정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
        달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
        경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
        달리하여 보존합니다.
      </p>
      <p>개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
      <ul>
        <li>
          • 파기 절차: <br />
          회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
          보호책임자의 승인을 받아 개인정보를 파기합니다.
        </li>
        <li>
          • 파기 방법: <br />
          회사는 전자적 파일 형태로 기록・저장된 개인정보는 기록을 재생할 수
          없도록 파기하며, 종이 문서에 기록・저장된 개인정보는 분쇄기로
          분쇄하거나 소각하여 파기합니다.
        </li>
      </ul>
      <br />
      <h3>제5조 개인정보의 제3자 제공</h3>
      <br />
      <p>
        회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위
        내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보
        보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
        제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.
      </p>
      <p>
        회사는 원활한 서비스 제공을 위해 다음의 경우 개인정보 보호법 제17조
        제1항 제1호에 따라 정보주체의 동의를 얻어 필요 최소한의 범위로만
        제공합니다.
      </p>
      <ul>
        <li>제공받는 자 : (주)서진인포텍</li>
        <li>
          제공목적 제공항목 보유 및 이용기간 : 행사 등록 및 입장, 홈페이지
          사전등록
        </li>
      </ul>
      <p>
        다음은 공동 주최 및 주관 기관들로 행사 운영 중 요청이 들어온 경우 원활한
        서비스 제공을 위해 회사는 개인정보 보호법 제17조 제1항 제1호에 따라
        정보주체의 동의를 얻어 필요 최소한의 범위로만 제공합니다.
      </p>
      <p>제공받는 자 제공목적 제공항목 보유 및 이용기간</p>
      <p>한국디지털교육협회 행사 공동 주관</p>
      <p>한국에듀테크산업협회 행사 공동 주관</p>

      <br />
      <h3>제6조 개인정보 처리의 위탁</h3>
      <br />
      <p>
        회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
        위탁하고 있습니다.
      </p>
      <p>위탁받는 자(수탁자) 위탁업무</p>
      <p style={{ color: 'red' }}>(주)서진인포텍 행사 등록 및 입장 운영 위탁</p>
      <p>
        회사는 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무
        수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한,
        수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
        명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
      </p>
      <br />
      <h3>제7조 개인정보의 안정성 확보조치</h3>
      <br />
      <p>
        회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
      </p>
      <ul>
        <li>
          • 관리적 조치 : 내부관리계획 수립・시행, 전담조직 운영, 정기적 직원
          교육
        </li>
        <li>
          • 기술적 조치 : 개인정보처리시스템 등의 접근 권한 관리, 접근통제시스템
          설치, 보안프로그램 설치 및 갱신
        </li>
        <li>• 물리적 조치 : 자료보관실 등의 접근통제</li>
      </ul>
      <br />
      <h3>제8조 개인정보 자동 수집 장치의 설치・운영 및 거부</h3>
      <br />
      <ul>
        <li>
          • 설치・운영하는 개인정보 자동 수집 장치
          <ul>
            <li>
              회사는 사용자에게 개별적인 서비스와 편의를 제공하기 위해
              이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
            </li>
            <li>
              정보주체는 웹 브라우저 옵션 설정을 통해 쿠키 허용, 차단 등의
              설정을 할 수 있습니다. 다만, 쿠키 저장을 거부할 경우 맞춤형 서비스
              이용에 어려움이 발생할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          • 거부 방법:
          <ul>
            <li>
              1. 웹 브라우저에서 쿠키 허용/차단
              <ul>
                <li>
                  - 크롬(Chrome): 웹 브라우저 설정 {'>'} 개인정보 보호 및 보안
                  {'>'} 인터넷 사용 기록 삭제
                </li>
                <li>
                  - 엣지(Edge): 웹 브라우저 설정 {'>'} 쿠키 및 사이트 권한 {'>'}{' '}
                  쿠키 및 사이트 데이터 관리및삭제
                </li>
              </ul>
            </li>
            <li>
              2. 모바일 브라우저에서 쿠키 허용/차단
              <ul>
                <li>
                  - 크롬(Chrome) : 모바일 브라우저 설정 {'>'} 개인정보 보호 및
                  보안 {'>'}인터넷 사용 기록 삭제
                </li>
                <li>
                  - 사파리(Safari) : 모바일 기기 설정 {'>'} 사파리(Safari) {'>'}
                  고급 {'>'} 모든 쿠키 차단 -삼성인터넷:모바일브라우저설정 {'>'}
                  인터넷사용기록 {'>'} 인터넷사용기록삭제
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <br />
      <h3>제9조 정보주체와 법정대리인의 권리・의무 및 행사</h3>
      <br />
      <p>
        정보주체는 개인정보에 대해 언제든지 개인정보 열람・정정・삭제・처리정지
        및 철회 요구, 자동화된 결정에 대한 거부 또는 설명 요구 등의 권리를
        행사(이하 “권리 행사”라 함)할 수 있습니다.
      </p>
      <p>
        ※ 14세 미만 아동에 관한 개인정보의 열람등 요구는 법정대리인이 직접 해야
        하며, 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여
        미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도
        있습니다.
      </p>
      <p>
        권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라
        서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에
        대해 지체없이 조치하겠습니다.
      </p>
      <p>
        권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여
        하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 위임장을
        제출하셔야 하며 위임장 양식은 회사에게 요청 확인 시 즉시 전달하겠습니다.
      </p>
      <p>
        정보주체가 개인정보 열람 및 처리 정지를 요구할 권리는 「개인정보
        보호법」 제35조 제4항 및 제37조 제2항에 의하여 제한될 수 있으며 다른
        법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 해당
        개인정보의 삭제를 요구할 수 없습니다.
      </p>
      <p>
        회사는 권리 행사를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
      </p>
      <br />
      <h3>제10조 개인정보 보호책임자</h3>
      <br />
      <p>
        회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
        관련한 정보주체 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
        보호책임자를 지정하고 있습니다.
      </p>
      <ul>
        <li>• 개인정보보호책임자</li>
      </ul>
      <p style={{ color: 'blue' }}>
        성명 : 광주글로벌게임센터 / gjgc@gicon.or.kr
      </p>
      <p>
        정보주체는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
        개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
        보호책임자로 문의할 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이
        답변 및 처리해드릴 것입니다.
      </p>
      <br />
      <h3>제11조 권익침해 구제방법</h3>
      <br />
      <p>
        정보주체는 개인정보침해로 인한 구제를 받기 위하여
        개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
        분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
        신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
      </p>
      <ul>
        <li>
          1. 개인정보 분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
        </li>
        <li>2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
        <li>3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
        <li>4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
      </ul>
      <br />
      <h3>개인정보 처리방침의 변경</h3>
      <br />
      <p>이 개인정보 처리방침은 2024. 09. 10부터 적용됩니다.</p>
    </div>
  );
}

export default Agreement;
