import React from 'react';
import styled from 'styled-components';

const LabelContainer = styled.label`
  width: 100px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const RequiredMark = styled.span`
  color: red;
  margin-left: 5px;

  @media (max-width: 768px) {
    margin-left: 3px;
  }
`;

interface LabelWithRequiredMarkProps {
  label: string;
  required?: boolean;
}

const LabelWithRequiredMark: React.FC<LabelWithRequiredMarkProps> = ({
  label,
  required = false,
}) => {
  return (
    <LabelContainer>
      {label}
      {required && <RequiredMark>*</RequiredMark>}
    </LabelContainer>
  );
};

export default LabelWithRequiredMark;
