import React from 'react';
import styled from 'styled-components';
import footerLogo from '../assets/images/footerLogo.png';

const FooterContainer = styled.div`
  display: flex;
  background-color: #f1f1f1;
  align-items: center;
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 50px 380px;
  gap: 10px;
  @media (max-width: 1440px) {
    padding: 50px 200px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 40px 100px;
  }
  @media (max-width: 968px) {
    padding: 20px;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  p {
    text-align: end;
  }
  @media (max-width: 1024px) {
    p {
      text-align: center;
    }
  }
  @media (max-width: 668px) {
    p {
      font-size: 12px;
    }
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <Container>
        <img src={footerLogo} alt="Logo" width={200} />
        <LeftContainer>
          <p>행사 문의 : infoggdc@gmail.com | 1566-2325</p>
          <p>© Copyright 2024 All Rights Reserved by GGDC운영사무국.</p>
        </LeftContainer>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
