import React from 'react';
import styled from 'styled-components';
import HeaderBanner from '../../components/mainPage/HeaderBanner';
import Footer from '../../components/Footer';
import mainVideo from '../../assets/video/mainVideo.mp4';

// 전체 페이지 컨테이너 스타일
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// 내부 컨테이너 스타일
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 380px 100px;

  /* 그라데이션 적용: 위쪽은 흰색, 아래쪽은 파란색 */
  background-color: #fff;

  @media (max-width: 1440px) {
    padding: 0px 200px 100px;
  }

  @media (max-width: 1024px) {
    padding: 0px 100px 50px;
  }

  @media (max-width: 768px) {
    padding: 0px 50px 30px;
  }

  @media (max-width: 480px) {
    padding: 0px 20px 20px;
  }
`;

const Video = styled.video`
  width: 100%;
  box-shadow:
    3px 3px 10px rgba(0, 0, 0, 0.2),
    /* 부드러운 그림자 */ 6px 6px 20px rgba(0, 0, 0, 0.25),
    /* 더 깊은 그림자 */ inset 2px 2px 5px rgba(0, 0, 0, 0.2),
    /* 안쪽 그림자 */ inset -2px -2px 5px rgba(0, 0, 0, 0.4);
`;

// 제목 스타일
const Title = styled.h1`
  width: 100%;
  display: flex;
  font-size: 60px;
  padding: 50px 0px;
  color: #252525;
  align-items: flex-end;
  justify-content: center;
  font-weight: 900;
  gap: 10px;

  /* 텍스트에 더 입체감을 주는 다중 text-shadow */
  text-shadow:
    1px 1px 0px #fff,
    /* 첫 번째 그림자는 흰색(아래와 대조되도록) */ 2px 2px 0px #b3b3b3,
    /* 두 번째 그림자(중간 음영) */ 3px 3px 2px rgba(0, 0, 0, 0.3),
    /* 세 번째 그림자(부드러운 그림자) */ 4px 4px 3px rgba(0, 0, 0, 0.2),
    /* 네 번째 그림자(더 깊은 그림자) */ 5px 5px 4px rgba(0, 0, 0, 0.1); /* 다섯 번째 그림자(아주 깊은 입체감) */

  @media (max-width: 768px) {
    font-size: 40px;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }

  span {
    font-size: 40px;
    /* 텍스트에 입체감을 주는 다중 text-shadow */
    text-shadow:
      1px 1px 0px #fff,
      2px 2px 0px #b3b3b3,
      3px 3px 2px rgba(0, 0, 0, 0.3),
      4px 4px 3px rgba(0, 0, 0, 0.2),
      5px 5px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      font-size: 25px;
    }

    @media (max-width: 480px) {
      font-size: 17px;
    }
  }
`;

function MainPage() {
  return (
    <PageContainer>
      <HeaderBanner />
      <Container>
        <Title>
          GGDC <span>K-GAME의 시작, 광주</span>
        </Title>
        {/* 로컬 비디오 파일을 넣는 경우 */}
        <Video autoPlay muted loop controls controlsList="nodownload">
          <source src={mainVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
        <p style={{ marginTop: 50 }}>추가 개발중</p>
      </Container>
      <Footer />
    </PageContainer>
  );
}

export default MainPage;
