import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // 페이지 이동을 위한 hook
import ReceptionHeader from '../../../components/receptionPage/ReceptionHeader';
import Agreement from '../../../components/receptionPage/Agreement';
import { ReceptionUrl } from '../../../url/ggdcUrl';
import Footer from '../../../components/Footer';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 380px 40px;
  @media (max-width: 1440px) {
    padding: 100px 200px 0px;
  }
  @media (max-width: 1024px) {
    padding: 100px 100px 0px;
  }
  @media (max-width: 968px) {
    padding: 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-content: center;
`;
const HeaderTitle = styled.h1`
  text-align: center;
  padding: 40px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  font-size: 40px;
  @media (max-width: 968px) {
    font-size: 30px;
    padding: 30px 20px;
  }
`;
const HeaderDecs = styled.p`
  text-align: center;
  width: 100%;
  padding: 40px;
  @media (max-width: 968px) {
    padding: 30px 20px;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  padding: 20px;
  max-width: 100%;
  background-color: #f9f9f9;
  text-align: left;
  font-size: 14px;
  white-space: pre-wrap; // 줄바꿈 유지
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-bottom: 40px;
`;

const Button1 = styled.button`
  background-color: ${(props) => props.theme.colors.mainColor};
  color: ${(props) => props.theme.colors.whiteColor};
  border: none;
  border: 1px solid ${(props) => props.theme.colors.mainColor};
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.hoverColor};
  }
`;
const Button2 = styled.button`
  background-color: ${(props) => props.theme.colors.whiteColor};
  color: ${(props) => props.theme.colors.textColor};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #888;

  &:hover {
    border: 1px solid #bbbbbb;
    background-color: #bbbbbb;
  }
`;

function ReceptionAgreementPage() {
  const [isAgreed, setIsAgreed] = useState(false); // 동의 여부 상태 관리
  const navigate = useNavigate(); // 페이지 이동을 위한 hook

  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handleConfirm = () => {
    if (isAgreed) {
      // 동의했을 때 다음 페이지로 이동
      navigate(ReceptionUrl); // 'next-page'에 원하는 페이지 경로를 넣으면 됩니다
    } else {
      // 동의하지 않았을 때 경고창 띄우기
      alert('개인정보에 동의하셔야 접수를 하실 수 있습니다.');
    }
  };

  return (
    <PageContainer>
      <ReceptionHeader />
      <Container>
        <HeaderContainer>
          <HeaderTitle>사전접수 안내</HeaderTitle>
          <HeaderDecs>개인정보의 수집, 이용에 관한 동의서</HeaderDecs>
        </HeaderContainer>
        <ContentBox>
          <Agreement />
        </ContentBox>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="agree"
            checked={isAgreed}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="agree">동의합니다</label>
        </CheckboxContainer>
        <ButtonContainer>
          <Button1 onClick={handleConfirm}>확인</Button1>
          <Button2 onClick={() => navigate(-1)}>취소</Button2> {/* 취소 버튼 */}
        </ButtonContainer>
      </Container>
      <Footer />
    </PageContainer>
  );
}

export default ReceptionAgreementPage;
