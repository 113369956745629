import SubHeaderContainer from '../../components/SubHeaderContainer';
import SubBanner from '../../components/SubBanner';
import WebPath from '../../components/WebPath';
import styled from 'styled-components';
import Footer from '../../components/Footer';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 380px 40px;
  @media (max-width: 1440px) {
    padding: 100px 200px 0px;
  }
  @media (max-width: 1024px) {
    padding: 100px 100px 0px;
  }
  @media (max-width: 968px) {
    padding: 20px;
  }
`;

function SpeakerPage() {
  return (
    <PageContainer>
      <SubBanner title="연사 소개" />
      <WebPath subtitle="연사 소개" />
      <Container>
        <SubHeaderContainer title="연사 소개" />
        <p style={{ margin: '50px 0px', fontSize: '30px' }}>준비중 입니다.</p>
      </Container>
      <Footer />
    </PageContainer>
  );
}

export default SpeakerPage;
