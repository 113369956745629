import apiClient from '../../apiClient';
import API_ENDPOINTS from '../../../api/endpoints/apiEndpoints';

export interface ReceptionData {
  visitDate: string;
  visitorName: string;
  visitorAffiliation: string;
  position: string;
  gender: string;
  visitorAge: string;
  visitorEmail: string;
  visitorMobilePhone: string;
  visitorLandlinePhone: string;
  visitorRegion: string;
  affiliationCategory: string;
  participationPurposes: string[]; // 배열 형태로 목적 전달
  interestField: string;
  inflowPath: string;
}
interface ReceptionResponse {
  data: null;
  resultMsg: string;
  status: number;
  message: string;
}

export const receptionAdd = async (data: ReceptionData) => {
  try {
    const response = await apiClient.post<ReceptionResponse>(
      API_ENDPOINTS.post_addNewResister(),
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
