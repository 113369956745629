import AuthEndpoints from './auth/authEndpoints';
import ReceptionEndpoints from './reception/receptionEndpoints';
// 다른 엔드포인트 파일들을 import

const API_ENDPOINTS = {
  ...AuthEndpoints,
  ...ReceptionEndpoints,
  // 다른 엔드포인트들을 여기에 추가
};

export default API_ENDPOINTS;
