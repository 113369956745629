import React from 'react';
import styled from 'styled-components';

interface ReceptionTitleProps {
  title: string; // 관람 예정일자
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h3`
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 17px;
  }
`;

const RequiredMark = styled.span`
  color: red;
  margin-left: 5px;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 17px;
    margin-left: 3px; /* 작은 화면에서 간격 줄이기 */
  }
`;

const ReceptionTitle: React.FC<ReceptionTitleProps> = ({ title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <RequiredMark>*</RequiredMark>
    </Container>
  );
};

export default ReceptionTitle;
