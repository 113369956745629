import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { theme } from './assets/css/theme';
import MainPage from './pages/user/MainPage';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './assets/css/GlobalStyle';
import {
  AnnouncementUrl,
  EventUrl,
  FAQUrl,
  MainUrl,
  OverviewUrl,
  ProgramUrl,
  ReceptionAgreementUrl,
  ReceptionUrl,
  SpeakerUrl,
} from './url/ggdcUrl';
import ReceptionAgreementPage from './pages/user/receptionPage/ReceptionAgreementPage';
import ReceptionPage from './pages/user/receptionPage/ReceptionPage';
import OverviewPage from './pages/user/OverviewPage';
import SpeakerPage from './pages/user/SpeakerPage';
import ProgramPage from './pages/user/ProgramPage';
import EventPage from './pages/user/EventPage';
import AnnouncementPage from './pages/user/noticePage/AnnouncementPage';
import FaqPage from './pages/user/noticePage/FaqPage';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        {/* GGDC */}
        <Route path={MainUrl} element={<MainPage />} />
        <Route
          path={ReceptionAgreementUrl}
          element={<ReceptionAgreementPage />}
        />
        <Route path={OverviewUrl} element={<OverviewPage />} />
        <Route path={SpeakerUrl} element={<SpeakerPage />} />
        <Route path={ProgramUrl} element={<ProgramPage />} />
        <Route path={EventUrl} element={<EventPage />} />
        <Route path={ReceptionUrl} element={<ReceptionPage />} />
        <Route path={AnnouncementUrl} element={<AnnouncementPage />} />
        <Route path={FAQUrl} element={<FaqPage />} />
      </Routes>
    </ThemeProvider>
  );
};
export default App;
